.game__item {
  border: 2px solid #ffffff;
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffffac;
 
}
.topmargin{
  margin-top: 10%;
}


#bw{
  width: 30%;

}


.game__item-btn {
  border-radius: 0;
  border: 2px solid #232323;
  box-shadow: 4px 4px 4px #3e3e3e;
  outline: none;
  background: #8d43a6;
  padding: 8px 0px;
  margin:5% 0% 0% 13%;
}

.game__item-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.game__item-btn a:hover {
  color: #fff;
} 
.game__btn-details {
  background: #8d43a6;
  border-radius: 5px 5px 5px 5px;

}
.game__btn-details:active {
  background: #723686;
  border-radius: 5px 5px 5px 5px;
box-shadow: none;
}


.game__item-content h4 {
  font-size: 1.3rem;
  color: #8d43a6;
  letter-spacing: 1px;
  text-shadow: none;

  
 }

.game__item-content h6 {
  font-size: 1.2rem;
 
  font-weight: 700;
  
}

@media only screen and (max-width: 992px) {
  .game__item {
    border: 5px solid #ffffff;
    background-color: #ffffff;
   
  }
  .game__item h4 {
    font-size: 1.1rem;
 

  }

  .game__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .game__item-info {
    flex-wrap: wrap;
  }

  .game__item-info span {
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .game__item-btn {
    padding: 5px 5px;
    font-size: 0.9rem;
    margin-left: 13.5%;
  }

  #bw{
    width: 30%;
  
  }
  .topmargin{
    margin-top: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .game__item {
    border: 5px solid #ffffff;
    background-color: #ffffff;
   
  }
  .game__item h4 {
    font-size: 1rem;
  }

  .game__item h6 {
    font-size: 0.9rem;
  }

  .game__item-info span {
    font-size: 0.7rem;
  }

  .game__item-info span i {
    font-size: 0.9rem;
  }

  .game__item-btn {
    font-size: 0.8rem;
  }
  #bw{
    width: 30%;  
  }
  .topmargin{
    margin-top: 10%;
  }
}

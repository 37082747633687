.align__contact {
  align-items: center;
  margin-left: 35.75%;
  margin-top: 9%;
  box-shadow: 0px 0px 2px solid #8d43a6;
}
.imgsiz{
  margin-left: 35%;
  height: 25%;
  width: 25%;

}

.contactfont{
text-align: center;
font-family: 'poppins', sans-serif;
color: #8d43a6;
}

.formstil{
color: #8d43a6;
font-size: large;
font-family: 'poppins', sans-serif;
margin: 0% 5%;
padding-bottom: 5%;
}


.btnstil{
  margin-left: 37%;
  background-color: #8d43a6;
  border: none;
  color: #fff;
  font-family: 'poppins', sans-serif;
  padding: 2% 6%;
  border-radius: 5%;
  border: 2px solid white;
  box-shadow:4px 4px 4px #000000;
}
.btnstil:active{
  padding: 2% 5.9%;
  box-shadow:0px 0px 0px #000000;
}

.back {
  height: 100%;
  width: 27em;
  background-color: #232323;
  padding: 2% 0% 0% 0%;
  border-radius: 2%;
  box-shadow: 0px 0px 2px solid #8d43a6;
}

.font1 {
  font-size: xx-large;
  color: #8d43a6;
  text-shadow: 0px 0px 2px #000000, 0px 0px 2px #000000;
  font-family: 'poppins', sans-serif;
}  

.font {
  font-size: larger;
  color: #8d43a6;
  text-shadow: 0px 0px 2px #000000, 0px 0px 2px #000000;
  font-family: 'poppins', sans-serif;
}

.contact__btn {
  padding: 7px 15px;
  background: #8d43a6;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .align__contact {
    align-items: center;
    margin-left: 40%;

  }

  .contact__btn {
    margin-bottom: 40px;
    font-size: 0.8rem;
  }

  .back {
    height: 29em;
    width: 20em;
    background-color: #232323;
    padding: 12% 0% 0% 2%;
    margin: 35% 0% 0% -57%;
    border-radius: 2%;

  }

  .font1 {
    font-size: x-large;
    color: #8d43a6;
    text-shadow: 0px 0px 2px #000000, 0px 0px 2px #000000;
    font-family: 'poppins', sans-serif;
  }

  .font {
    font-size: 15px;
    color: #8d43a6;
    text-shadow: 0px 0px 2px #000000, 0px 0px 2px #000000;
    font-family: 'poppins', sans-serif;
  }
}
/* ============ header logo style =========== */
.header__middle {
    
  padding: 0px 0px 0px 0px;
}

.logo h1 a span {
  font-size: 1.5rem;
}

.logo_align {
  width: 17%;
}

.logo h1 a i {
  font-size: 2.2rem;
}

.logo h1 a {
  text-decoration: none;
  font-weight: 700;
}

.logo h1 {
  line-height: 1.5rem;
}

.header__location span i {
  font-size: 2.2rem;

}

.header__location-content h4 {
  font-size: 1.2rem;
  font-weight: 700;

}

.header__location-content h6 {
  font-weight: 600;
}

.header__btn {
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.header__btn a {

  align-items: center;
  column-gap: 0.4rem;
  text-decoration: none;

  justify-content: end;
  font-size: 0.9rem;
}
/* -----------Navigation for header text---------- */

.headertext{
  background-color: #fff;
  width: 100%;
  padding: 8px ;
  

}
.headertext p{
  color: white;
  display: flex;
  justify-content: center;
  align-items:center;
}

/* ================ main navbar styles =========== */

.main__navbar {
  position: fixed;
   width: 100%;
 /* top: 10;
  left: 0;
  z-index: 1000; Ensure the navbar is on top of other content 
 
  padding: 10px 0px;*/
  background: #232323;
}

.mobile__menu i {
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
}

.nav__item {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  font-family: 'poppins', sans-serif;
}

.nav__item:hover {
  color: #8d43a6;
 /* background: linear-gradient(to left, white 10%,  #8d43a6 50%)right ;
  background-size: 200% 100%;
  border-radius: 1px;
  padding: 2px 3px;
   /*border-bottom: 1px solid #8d43a6;
  /*border-right: 2px solid #8d43a6; */
  
}





.nav__active {
  color: #000000;
  background: linear-gradient(to left, white 5%,  #8d43a6 50%)right ;
  background-size: 200% 100%;
  border-radius: 2px;
  padding: 3px 6px;
  /* border-bottom: 2px solid #8d43a6;
  border-right: 2px solid #8d43a6; */
}




.main__navbar1 {
  position: fixed;
  width: 100%;
  padding: 0px 0px;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

}

.toptext {
  color: #8d43a6;
  padding: .5% 0%;
  text-align: center;
  font-family: 'poppins', sans-serif;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing:1px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {

  .header__top,
  .header__middle,
  .nav__right {
    display: none;
  }
  
  .toptext {
    color: #8d43a6;
    padding: .5% 0%;
    text-align: center;
    font-family: 'poppins', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    word-spacing: 15px;
  }

  .mobile__menu {
    display: block;
  }

  .navigation {
    background: rgba(23, 23, 23, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }

  .menu {
    width: 250px;
    height: 100%;
    background: #232323;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
  }

  .menu a {
    color: #8d43a6;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .menu__active {
    display: block;
  }
}

@media only screen and (max-width: 576px) {
  .logo_align {
    margin-left: 80%;
  }
}
.logo_align:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again 
  animation-iteration-count: infinite;*/
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.align_contact {
    align-items: center;
    
    margin: 9% 9% 0% 9%;
    box-shadow: 0px 0px 2px solid #8d43a6;
  }
  
  .bac {
    height: 100%;
    width: 100%;
    background-color: #232323;
    padding: 3.5% 3% 4% 2%;
    border-radius: 0.5% ;
    box-shadow: 0px 0px 2px solid #8d43a6;
  }
  
  .fon1 {
    font-size: xx-large;
    color: #8d43a6;
    text-shadow: 0px 0px 2px #000000, 0px 0px 2px #000000;
    font-family: 'poppins', sans-serif;
  }  
  
  .fon {
    font-size: 13px;
    color: #8d43a6;
    text-shadow: 0px 0px 2px #000000, 0px 0px 2px #000000;
    font-family: 'poppins', sans-serif;
  }
  
  .contact_btn {
    padding: 7px 15px;
    background: #8d43a6;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 0px;
  }
  
  @media only screen and (max-width: 768px) {
    .align_contact {
      align-items: center;
      /* margin:0% 8% 0% 8%; */
  
    }
  
    .contact_btn {
      margin-bottom: 40px;
      font-size: 0.5rem;
    }
  
    .bac {
      height: 100%;
      width: 100%;
      background-color: #232323;
      padding: 2% 0% 0% 2%;
      margin: 25% 0% 0% 0%;
      border-radius: 0.5%;
  
    }
  
    .fon1 {
      font-size: large;
      color: #8d43a6;
      text-shadow: 0px 0px 2px #000000, 0px 0px 2px #000000;
      font-family: 'poppins', sans-serif;
    }
  
    .fon {
      font-size:10px;
      color: #8d43a6;
      text-shadow: 0px 0px 2px #000000, 0px 0px 2px #000000;
      font-family: 'poppins', sans-serif;
    }
  }
.footer {
  background: #232323;
  padding-top: 50px;
  padding-bottom: 15px;
  
}

.f_logo{
  width: 60%;
  margin-top: -1em;
  margin-left: 1em;
}

.quick__link {
  border: none !important;
  background: transparent !important;
}

.quick__link a {
  color: rgba(255, 255, 255, 0.715);
  text-decoration: none;
  font-weight: 300;
  transition: 0.3s;
}

.quick__link a:hover {
  color: #8d43a6;
}

.footer__link-title {
  color: #fff;
}

.office__info {
  color: rgba(255, 255, 255, 0.715);
  font-weight: 300;
}

.footer__bottom {
  border-top: 1px solid #ffffff2f;
}

.contact__btn {
  padding: 7px 15px;
  background: #8d43a6;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
}

.IconStyle{
  text-decoration: none;
  color: #8d43a6;
  font-size: 3em;
}
.IconStyle:hover{
  text-decoration: none;
  color: #dd77ff;
}

@media only screen and (max-width: 768px) {
  .contact__btn {
    margin-bottom: 40px;
    font-size: 0.8rem;
  }
}


@media only screen and (max-width: 768px) {
  .footer__logo-content {
    font-size: 0.8rem;
  }

  .quick__link,
  .office__info {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .footer__link-title {
    font-size: 1rem;
    margin-top: 15px;
  }

  .footer__bottom p {
    font-size: 0.7rem;
  }
  .f_logo{
    width: 70%;
    margin-top: -1em;
    margin-left: 50px;
  } 

  #icon_col{
    color: #8d43a6;
    font-size: 3em;
    margin: 5%;
    margin-left: -20px;
    margin-right: 220px;
    margin-bottom: 20px;
  }
  
}
